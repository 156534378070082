import React, { useState } from 'react'; import './App.css';
import Chat from './Components/Chat';

import { fetchApiKey } from './Components/ApiService';

function App() {
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState('');

  const handlePasswordSubmit = async () => {
    try {
      const response = await fetchApiKey(password);
      if (response.status === 200) {
        localStorage.setItem('api_key', response.body.api_key);
        setAuthenticated(true);
      } else {
        setError(response.body.message);
      }
    } catch (err) {
      setError('An error occurred, please try again.');
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handlePasswordSubmit();
    }
  };

  if (!authenticated) {
    return (
      <div className="login-container">
        <h2>Please enter the password to access the site</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button onClick={handlePasswordSubmit}>Submit</button>
        {error && <p className="error">{error}</p>}
      </div>
    );
  }

  return (
    <div className="App">
      <Chat />
    </div>
  );
}

export default App;