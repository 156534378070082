import React, { useState, useEffect, useRef } from 'react';
import { createThread, addMessage, getResponse } from './ApiService';
import ReactMarkdown from 'react-markdown';
import { FaCopy } from 'react-icons/fa';
import { marked } from 'marked';
import './Chat.css';
import Alert from './Alert';
const Chat = () => {
    const [threadId, setThreadId] = useState(null);
    const [runId, setRunId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        const initializeChat = async () => {
            const threadData = await createThread();
            if (threadData.status !== 200) {
                setAlertMessage(threadData.body.message);
            } else {
                setThreadId(threadData.body.thread_id);
            }
        };
        initializeChat();
    }, []);

    const handleSendMessage = async () => {
        if (input.trim() === '') return;
        if (!threadId) {
            setAlertMessage('Unable to submit please reload.');
            return;
        }
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: input, user: true },
        ]);
        setInput('');
        setWaitingForResponse(true);
        setIsTyping(true);
        const messageData = await addMessage(threadId, input);
        if (messageData.status !== 200) {
            setAlertMessage(messageData.body.message);
        } else {
            setRunId(messageData.body.run_id);
            setWaitingForResponse(true);
        }
    };

    useEffect(() => {
        const checkResponse = async () => {
            if (waitingForResponse && threadId && runId) {
                const responseData = await getResponse(threadId, runId);
                if (responseData.status !== 200) {
                    setAlertMessage(responseData.body.message);
                    setIsTyping(false);
                    setWaitingForResponse(false);
                } else {
                    if (responseData.body.complete === 1) {
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            { text: responseData.body.content, user: false },
                        ]);
                        setWaitingForResponse(false);
                        setIsTyping(false);
                        setRunId(null);
                    } else {
                        setTimeout(checkResponse, 5000);
                    }
                }
            }
        };

        checkResponse();
    }, [waitingForResponse, threadId, runId]);
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);
    const handleCopy = async (text) => {
        const html = marked(text);
        try {
            await navigator.clipboard.write([
                new ClipboardItem({
                    'text/html': new Blob([html], { type: 'text/html' }),
                    'text/plain': new Blob([text], { type: 'text/plain' })
                })
            ]);
            console.log('Copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
        }
    };
    const closeAlert = () => {
        setAlertMessage('');
    };
    return (
        <div className="chat-container">
            <Alert message={alertMessage} onClose={closeAlert} />
            <div className="messages">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={msg.user ? 'message user-message' : 'message bot-message'}
                    >
                        <div className="message-text">
                            {msg.user ? msg.text : <ReactMarkdown>{msg.text}</ReactMarkdown>}
                        </div>
                        {!msg.user && (
                            <FaCopy className="copy-icon" onClick={() => handleCopy(msg.text)} />
                        )}
                    </div>
                ))}
                {isTyping && (
                    <div className="message bot-message typing-indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={input}
                    placeholder='Type a message...'
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default Chat;
