const baseUrl = process.env.REACT_APP_API_URL;
let apiKey = null;

export const setApiKey = (key) => {
    apiKey = key;
};

export const fetchApiKey = async (password) => {
    const response = await fetch(`${baseUrl}/public/verify`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password })
    });

    if (response.status === 200) {
        const json = await response.json();
        setApiKey(json.api_key);
        return {
            status: response.status,
            body: json,
        };
    } else {
        const error = await response.json();
        return {
            status: response.status,
            body: error,
        };
    }
};
export const createThread = async () => {
    const response = await fetch(`${baseUrl}/chat/create`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
        }
    });
    const json = await response.json();
    return {
        status: response.status,
        body: json,
    };
};

export const addMessage = async (thread_id, message) => {
    const response = await fetch(`${baseUrl}/chat/message`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
        },
        body: JSON.stringify({ thread_id, message })
    });
    const json = await response.json();
    return {
        status: response.status,
        body: json,
    };
};

export const getResponse = async (thread_id, run_id) => {
    const response = await fetch(`${baseUrl}/chat/response?thread_id=${thread_id}&run_id=${run_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
        }
    });
    const json = await response.json();
    return {
        status: response.status,
        body: json,
    };
};
